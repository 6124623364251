import React from "react"
import { observer } from "mobx-react-lite"
import { EuiText, EuiPanel, EuiImage } from "@elastic/eui"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import { navigate } from "gatsby"
import Images from "../../images"
import HailCXSingButtonFooter from "../../components/layout/footer/hailcx-single-button-footer"
import { useStore } from "../../stores"

const OfferExpiredPage = () => {
  const { facilityStore, bookingStore, customerStore, waitlistStore } =
    useStore()
  const data = {
    title: "Waitlist",
    buttonContent: "Manage booking",
  }
  return (
    <HailCXLayout
      header={<HailCXRightImageHeader title={data.title} progressValue={0} />}
      footer={
        <HailCXSingButtonFooter
          buttonContent={data.buttonContent}
          isLoading={false}
          handleClick={() => {
            navigate(
              `/book/manage-booking?eqn=${customerStore.encryptedQuoteNumber}&bookingNumber=${bookingStore.bookingNumber}`
            )
          }}
        />
      }
    >
      <EuiPanel>
        <EuiImage src={Images.Clock} alt="Offer Expired" />
        <EuiText>
          <strong>Sorry, you missed out!</strong>
          <p>
            just a reminder, earlier booking offers expire 24 hours after
            they're sent.
          </p>
          <p>We'll keep you in the loop when new booking times come up.</p>
        </EuiText>
      </EuiPanel>
    </HailCXLayout>
  )
}

export default observer(OfferExpiredPage)
